<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="'/counteragentdetails/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DETAILS') }} </span>
        </router-link>

        <!-- <v-dialog v-model="dialog" persistent max-width="450">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="navi-link">
              <span class="navi-icon">
                <i class="flaticon-file-1 text-success"></i>
              </span>
              <span class="navi-text ">{{ $t('ACTION.DETAILS') }} </span>
            </div>
          </template>
          <v-card>
            <v-card-title class="headline">Kontragent malumotlari</v-card-title>
            <v-card-text>
              <div class="input">
                <label>Kontragent nomi</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="index.full_name"
                />
              </div>

               <div class="input">
                <label>Inn</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="index.inn"
                />
              </div>

               <div class="input">
                <label>Telefon raqam</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="index.phone"
                />
              </div>
              <div class="input">
                <label>Yuridik manzil</label>
                <input
                  type="text"
                  disabled
                  class="input-text"
                  :value="index.juridik_adress"
                />
              </div>
              
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false"
                >Yopish</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog> -->

        <!-- <router-link :to="'/contractupdate/' + index.id" class="navi-link">
          <span class="navi-icon ">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>
        <router-link :to="'/addContract/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-file-1"></i>
          </span>
          <span class="navi-text">Qoshimcha shartnoma+</span>
        </router-link>
        <router-link :to="'/contractappenreg/' + index.id" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-file-1"></i>
          </span>
          <span class="navi-text">Maxsus ilova +</span>
        </router-link> -->
      </b-dropdown-text>
      <!--      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-graph-1"></i>
          </span>
          <span class="navi-text">Order</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-calendar-4"></i>
          </span>
          <span class="navi-text">Event</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-layers-1"></i>
          </span>
          <span class="navi-text">Report</span>
        </a>
      </b-dropdown-text>
      <b-dropdown-text tag="div" class="navi-item">
        <a href="#" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon2-calendar-4"></i>
          </span>
          <span class="navi-text">Post</span>
        </a>
      </b-dropdown-text> -->
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<style lang="scss">
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
<style>
.input label {
  display: block;
  font-size: 13px;
}
.input {
  margin-right: 6px;
  margin-bottom: 30px;
}
.input:last-child {
  margin-right: 0;
}
.input-text {
  width: 100%;
  background-color: rgb(142 190 253 / 14%);
  padding: 1rem;
  border: 1px solid rgb(142 190 253 / 28%);
  color: inherit;
  font-size: 16px;
  border-radius: 3px;
}
</style>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  name: 'ContractsAction',
  props: {
    allUsers: Array,
    btnClass: String,
    index: Object,
    id: Number
  },

  components: {},
  mounted() {}
}
</script>
