<template>
  <div class="card-body">
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      loading-text="Malumot yuklanmoqda..."
      :items="allSkladList"
      no-data-text="Malumot kiritilmagan"
      hide-default-footer
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          allSkladList
            .map(function (x) {
              return x.id
            })
            .indexOf(item.id) + 1
        }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <KontragentAction :index="item"></KontragentAction>
      </template>
    </v-data-table>
    <div class="text-left mt-5">
      <v-pagination
        v-model="currentPage"
        :total-visible="7"
        @input="getPostData(currentPage)"
        :length="Math.ceil(getCount.count / 10)"
      ></v-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import KontragentAction from './kontragentAction'
export default {
  components: { KontragentAction },
  data: () => ({
    perPage: 10,
    currentPage: 1
  }),
  created() {
    this.$store.dispatch('getAllMfoBank')
    this.currentPage = parseInt(this.$route.params.id, 10)
    this.getPostData(this.currentPage)
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentPage = parseInt(id, 10)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'full_name'
        },
        {
          text: this.$t('TABLE_HEADER.INN'),
          value: 'inn'
        },
        {
          text: this.$t('TABLE_HEADER.PHONE_NUMBER'),
          value: 'phone'
        },
        {
          text: this.$t('TABLE_HEADER.JURIDIK_ADDRESS'),
          value: 'juridik_adress'
        },
        {
          text: 'Bank',
          value: 'bank'
        },
        {
          text: 'Bank hisob raqami',
          value: 'bank_account'
        },
        {
          value: 'action',
          text: '',
          sortable: false
        }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.kontragentsList
    },
    allSkladList() {
      var data = []
      data = this.$store.state.requests.kontragentsList.results
      console.log(data)
      //   if (data !== undefined && data.length !== undefined) {
      //     data.forEach(element => {
      //       if (element.responsible !== null) {
      //         element.responsibleName =
      //           element.warehouse_responsible.first_name +
      //           " " +
      //           element.warehouse_responsible.last_name;
      //       }
      //     });
      return data
      //   } else return data;
    }
  },
  methods: {
    getPostData(value) {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          // id: value,
          ...this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('searchKontragentsList', {
          filters: asd,
          page: value
        })
        if (this.$route.path !== '/kontragents/' + value) {
          this.$router.push('/kontragents/' + value)
        }
      } else {
        this.$store.dispatch('getKontragentsList', { filters: {}, page: value })
        if (this.$route.path !== '/kontragents/' + value) {
          this.$router.push('/kontragents/' + value)
        }
      }
    }
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
